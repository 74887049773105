
































import { Usuario } from "@/core/models/geral";
import { PageBase } from "@/core/models/shared";
import { UsuarioService } from "@/core/services/geral";
import { Component, Watch } from "vue-property-decorator";

@Component
export default class ConfiguracaoImpressaoClienteUsuario extends PageBase{
    
    valid: boolean = true;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    usuarios: Usuario[] = [];
    usuarioService : UsuarioService = new UsuarioService();
    isUsuarioLoading:boolean =false;
    onSearchUsuario: any = null;

    $refs!: {
        form: HTMLFormElement
    }

    filtro: any = {
        usuarioId:  0
    }

    @Watch('onSearchUsuario')
    SearchUsuario (val:string){
        if(this.filtro.usuarioId)
            return;
        if(this.isUsuarioLoading)
            return;
        if(!val)
            return;

        this.isUsuarioLoading = true;

        this.usuarioService.AutoComplete(val).then(
            res =>{
                this.usuarios = res.data;
            },
            err => this.$swal('Aviso',err.response.data,'error')
        ).finally(() =>{
            this.isUsuarioLoading = false;
        })
    }

    GerarRelatorio(){
        if(this.$refs.form.validate()){
            let routeLink = this.$router.resolve({name:"impressaoClienteUsuario",
            query:{
                usuarioId:this.filtro.usuarioId,
            }
            });
            window.open(routeLink.href,'_blank')
        }
    }

    LimparFiltros(){
        this.filtro.usuarioId = 0;
        this.$refs.form.resetValidation();
    }
}
